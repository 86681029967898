import React from 'react';
import { useState, useEffect } from 'react';
import {
    Alert,
    Box,
    Grid,
    Paper,
    Typography,
} from '@mui/material';
import { ControlPointOutlined } from '@mui/icons-material';
import CommonPageBaseLayout from '../../organism/Common.PageBaseLayout/Common.PageBaseLayout';
import serviceStatusData from './service_status.json';

/**
 *
 * @param {React.PropsWithChildren<IServiceStatus>} props
 * @return {JSX.Element}
 * @constructor
 */

interface IServiceStatusData {
    _type: string;
    date: string;
    description: string;
    status: string;
    resolution_date: string;
}


const ServiceStatus: React.FC<IServiceStatus> = (props) => {
    if (Array.isArray(serviceStatusData)) {
    }

    const currentDate = new Date();

    const hideAlert = (item: any) => {
        if (item._type === 'relevant') {
            const currentDate = new Date();
            const endDate = new Date(item.resolution_date);
            const differenceInMilliseconds = currentDate.getTime() - endDate.getTime();
            const millisecondsIn7Days = 7 * 24 * 60 * 60 * 1000;
            return differenceInMilliseconds > millisecondsIn7Days;
        }
        return false;
    };

    const formatDate = (dateString : string) => {
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
    };


    // Filtra gli elementi rilevanti che non devono essere nascosti
    const relevantStatusUpdates = serviceStatusData
        .filter(item => item._type === 'relevant' && !hideAlert(item))
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        .slice(0, 20);

    return (
        <CommonPageBaseLayout>
            <Typography variant={"h1"} fontWeight={600}>
                Service Status
            </Typography>
            <Typography variant={"subtitle1"} mt={1}>
                <strong>Relevant Status Updates</strong> section displays major updates from TTVAM measurement system. <strong>Planned System Works</strong> section displays alerts to inform the users about incoming system unavailability.
                Status update alerts will disappear 7 days after their resolution.
            </Typography>

            {/* RELEVANT STATUS UPDATES */}
            <Box sx={{ marginBottom: '5%' }}>
                <Grid item xs={12} mt={'50px'} sx={{ maxHeight: '500px' }}>
                    <Paper sx={{ padding: 2, minHeight: "100%", borderRadius: '15px' }}>
                        <Typography variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>RELEVANT STATUS UPDATES</Typography>
                        {relevantStatusUpdates.length > 0 ? (
                            <Grid container spacing={0} sx={{ overflow: 'auto', maxHeight: '300px' }}>
                                {relevantStatusUpdates.map((item, index) => (
                                    <Alert
                                        key={index}
                                        severity={item.status === 'not_solved' ? "error" : "success"}
                                        sx={{ minWidth: "99%", borderRadius: '10px', mt: index > 0 ? 1 : 0 }}
                                    >
                                        <strong>{formatDate(item.date)}</strong>: {item.description}
                                    </Alert>
                                ))}
                            </Grid>
                        ) : (
                            <Alert severity={'info'}
                                   sx={{ minWidth: "99%", borderRadius: '10px'}}
                            >
                                No relevant status updates to show
                            </Alert>
                        )}
                    </Paper>
                </Grid>

                {/* PLANNED SYSTEM WORKS */}
                <Grid item xs={12} sx={{ maxHeight: '500px', marginTop: '4%' }}>
                    <Paper sx={{ padding: 2, minHeight: "100%", borderRadius: '15px' }}>
                        <Typography variant={"h5"} fontWeight={"bold"} sx={{ mb: 2 }}>PLANNED SYSTEM WORKS</Typography>
                        {serviceStatusData.filter(item => item._type === 'maintenance').length > 0 ? (
                            <Grid container spacing={0} sx={{ overflow: 'auto', maxHeight: '300px' }}>
                                {serviceStatusData
                                    .filter(item => item._type === 'maintenance')
                                    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                                    .map((item, index) => (
                                        <Alert
                                            key={index}
                                            severity="warning"
                                            sx={{
                                                width: "100%", // Ensure all alerts take full width
                                                borderRadius: '10px',
                                                mt: index > 0 ? 1 : 0,
                                                wordWrap: "break-word", // Wrap long content
                                                overflowWrap: "anywhere", // Handle unbreakable strings
                                            }}
                                        >
                                            <strong>{formatDate(item.date)}</strong>: {item.description}
                                        </Alert>
                                    )
                                )}
                            </Grid>
                        ) : (
                            <Alert severity={'info'}>No planned system works to show</Alert>
                        )}
                    </Paper>
                </Grid>
            </Box>
        </CommonPageBaseLayout>
    );
};

export interface IServiceStatus {

}

export default ServiceStatus;

