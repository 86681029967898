import React, {useEffect, useMemo, useState} from 'react';
import { Box, Container, Typography, Badge } from '@mui/material';
import UserSection from './UserSection';
import { Link, useMatch, useNavigate, useParams } from 'react-router-dom';
import { RouteKey } from '../../../App.Routes';
import { useQuery } from '@apollo/client';
import { generateClientContextWithHeaders } from '../../../utils/graphql/graphQLsupportFunction';
import { useAuthContext } from '../../../context/AuthContext';
import { UserTeam, UserTeamRole } from '../../../utils/graphql/generatedTS/graphql';
import { UserQueries } from '../../../utils/graphql/queries/user';
import serviceStatusData from '../../page/serviceStatus/service_status.json';


/**
 *
 * @param {React.PropsWithChildren<ICommonHeader>} props
 * @return {JSX.Element}
 * @constructor
 */
const CommonHeader: React.FC<ICommonHeader> = (props) => {
  const {authenticatedIdUser, authToken, deleteAuthToken} = useAuthContext();
  const { data, loading } = useQuery(UserQueries.GET_USER_BY_ID, {
    variables: {
      idUser: authenticatedIdUser as string
    },
    context: generateClientContextWithHeaders(authToken),
    skip: !authenticatedIdUser,
  });


  const {idTeam} = useParams();
  const navigate = useNavigate();
  const matchDashboard = useMatch(RouteKey.Dashboard.replace(":idTeam", idTeam as string));
  const matchUserManagement = useMatch(RouteKey.UserManagement.replace(":idTeam", idTeam as string));
  const matchServiceStatus = useMatch(RouteKey.ServiceStatus.replace(":idTeam", idTeam as string));


  const currentTeam: UserTeam | undefined = useMemo(() => {
    const team = data?.getUserById?.teams?.filter(t => t?.team.idTeam === idTeam);
    if (!team || team.length === 0) return undefined;
    else return team[0] as UserTeam;
  }, [data?.getUserById?.teams, idTeam]);


// Red circle state
  const [showRedCircle, setShowRedCircle] = useState(() => {
    return localStorage.getItem('serviceStatusDismissed') !== 'true';
  });

  const handleServiceStatusClick = () => {
    setShowRedCircle(false);
    localStorage.setItem('serviceStatusDismissed', 'true');
  };

  //Use this to make the badge always visible to debug
  /*useEffect(() => {
    // Reset for debugging purposes
    localStorage.removeItem('serviceStatusDismissed');
    setShowRedCircle(true);
  }, []);*/


  return (
      <Box sx={{backgroundColor: "#212529"}}>
        <Container>
          <Box width={"100%"}
               display={"flex"} alignItems={"center"}
               paddingY={1.5}>
            {/*<img src={"/imgs/horse-white.svg"} height={43} alt={"Databreeders Logo"}/>*/}
            <Link to={RouteKey.Dashboard.replace(":idTeam", idTeam as string)}>
              <img
                  src={"/imgs/gradient_logo.png"}
                  style={{ marginRight: "50px", marginTop: "5px",cursor: 'pointer' }}
                  height={43}
                  alt={"SpotOn-Logo"}
              />
            </Link>
            <Box display={"flex"} alignItems={"center"}  flexGrow={10}>
              <Typography
                  variant={"h6"}
                  color={matchDashboard ? "white" : "#9b9d9e"}
                  //paddingX={4}
                  sx={{ cursor: "pointer"}}
                  component={Link}
                  to={RouteKey.Dashboard.replace(":idTeam", idTeam as string)}
              >
                Reports
              </Typography>
              <Badge
                  color="error"
                  variant="dot"
                  invisible={!showRedCircle} // Hide the badge when the red circle is dismissed
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    '& .MuiBadge-dot': {
                      transform: 'translate(6px, -50%)', // Adjust position relative to the text
                      marginRight: '26px', // Reduce spacing between the dot and text
                    },
                  }}
              >
                <Typography
                    variant={"h6"}
                    color={matchServiceStatus ? "white" : "#9b9d9e"}
                    sx={{ cursor: "pointer" }}
                    paddingX={4}
                    component={Link}
                    to={RouteKey.ServiceStatus.replace(":idTeam", idTeam as string)}
                    onClick={handleServiceStatusClick}
                >
                Service Status
              </Typography>
              </Badge>
              {!loading && currentTeam?.role === UserTeamRole.Admin && (
                  <>
                    <Typography
                        variant={"h6"}
                        color={matchUserManagement ? "white" : "#9b9d9e"}
                        sx={{ cursor: "pointer", marginRight: '30px' }}
                        component={Link}
                        to={RouteKey.UserManagement.replace(":idTeam", idTeam as string)}
                    >
                      Users Management
                    </Typography>

                  </>
              )}
            </Box>

            {/* User section */}
            <Box>
              <UserSection/>
            </Box>


          </Box>
        </Container>
      </Box>
  );
};


export interface ICommonHeader {


}


export default CommonHeader;
