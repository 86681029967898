import { Broadcaster } from './Broadcaster';

export type ChannelData = {
  id: number;
  name: string;
  broadcaster: Broadcaster | null;
};

export const CHANNELS_LIST: ChannelData[] = [
  {
    id: 0,
    name: "No TV channel",
    broadcaster: null
  },{
    id: 3,
    name: "MTV - MTV3",
    broadcaster: Broadcaster.MTV
  },{
    id: 12,
    name: "MTV - Sub",
    broadcaster: Broadcaster.MTV
  },{
    id: 65,
    name: "MTV - Ava",
    broadcaster: Broadcaster.MTV
  },
  {
    id: 72,
    name: "MTV - STAR (ex Sanoma until 31-12-2024)",
    broadcaster: Broadcaster.MTV
  },
  {
    id: 80,
    name: "MTV - National Geographic (ex Sanoma until 31-12-2024)",
    broadcaster: Broadcaster.MTV
  },
  {
  id: 5,
  name: "Sanoma - Nelonen",
  broadcaster: Broadcaster.SANOMA
},{
  id: 47,
  name: "Sanoma - JIM",
  broadcaster: Broadcaster.SANOMA
},{
  id: 68,
  name: "Sanoma - Liv",
  broadcaster: Broadcaster.SANOMA
},{
  id: 89,
  name: "Sanoma - Hero",
  broadcaster: Broadcaster.SANOMA
},{
    id: 13,
    name: "Warner Bros. Discovery - Eurosport 1",
    broadcaster: null
  },{
    id: 29,
    name: "Warner Bros. Discovery - TV5",
    broadcaster: null
  },{
    id: 51,
    name: "Warner Bros. Discovery - The Discovery Channel",
    broadcaster: null
  },{
    id: 74,
    name: "Warner Bros. Discovery - Kutonen",
    broadcaster: null
  },{
    id: 91,
    name: "Warner Bros. Discovery - Frii",
    broadcaster: null
  },{
    id: 100,
    name: "Warner Bros. Discovery - TLC",
    broadcaster: null
  }];
